<template>
  <div v-show="value" class="promotion-modal" :class="{ active: value }">
    <div class="wrapper w-11/12 md:w-3/5 lg:w-2/5 xl:w-2/5 xxl:w-1/3 xxxl:w-1/4 m-auto">
      <!-- <img class="absolute right-0 z-1 h-32" src="../../../../assets/images/elements/decore-right.png" /> -->
      <div class="flex flex-col w-full px-8 py-10 relative shadow-lg">
        <vs-row class="flex justify-center items-center mb-8">
          <img class="h-32" src="../../../../assets/images/elements/present2.png" />
        </vs-row>
        <vs-row class="justify-center items-center flex">
          <h2 class="pt-0 pb-3 text-center">Here's a freebie for you! </h2>
        </vs-row>

        <vs-row class="mb-8 flex justify-center items-center flex-col">
          <p class="pt-0 mb-2 text-center">We have credited your account with <strong>2 free Job Post Credits</strong>. </p>
          <p>Enjoy!</p>
        </vs-row>
        <vs-row class="flex justify-center items-center">
          <vs-button @click.prevent="close">Okay </vs-button>
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Promotion',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    jobType: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      employer: this.$store.getters['employerModule/getUser'],
    };
  },

  computed: {},
  methods: {
    close() {
      this.$store.dispatch('employerModule/patch', { showPromotion: false });
      this.$store.dispatch('togglePromotionModal', { active: false });
    },
  },
};
</script>

<style lang="css" scoped>
h2 {
  padding-bottom: 25px;
  color: #adca63;
}

.icon,
.modal-text {
  color: #626262 !important;
}

.promotion-modal {
  display: flex;
  position: fixed;

  margin-top: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  transition: transform 0.4s;
  z-index: 99999 !important;
}
.promotion-modal .wrapper {
  background-color: #fefefe;
  margin: auto;

  width: 50%;
  border: 0 !important;
  position: relative;
  border-radius: 8px;
  display: flex;
}

.promotion-modal .active {
  opacity: 1;
  z-index: 99999;
}
</style>
