<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="layout--main" :class="[navbarClasses, footerClasses, { 'app-page': isAppPage }]">
    <vx-sidebar
      :sidebar-items="sideBarItemsWithTags"
      :logo="require('@/assets/images/logo/logo.png')"
      title="Recruit a Guide"
      parent=".layout--main"
    />

    <vs-sidebar
      id="rag-sidebar"
      v-model="showSideBar"
      parent="body"
      default-index="1"
      position-right
      class="add-new-data-sidebar items-no-padding background-boi half-vs-sidebar min-w-full"
      :click-not-close="true"
    >
      <portal-target v-if="showSideBar" name="rag-sidebar"></portal-target>
      <portal-target v-if="showSideBar" slot="footer" name="rag-sidebar-footer"></portal-target>
    </vs-sidebar>

    <upgradeAccount v-model="purchaseCredits.active" :job-type="purchaseCredits.creditType">
      <template #header>
        <h2 class="mb-5 pt-3">{{ purchaseCredits.title }}</h2>
        <p class="mb-8">{{ purchaseCredits.text }}</p>
      </template>
    </upgradeAccount>

    <PromotionalModal v-model="promotionModal.active" />

    <vs-popup :title="generalPopup.title" :active.sync="generalPopup.active">
      <div class="mr-5">
        <p class="mb-5">{{ generalPopup.text }}</p>
        <vs-button color="primary" icon-before class="vx-col lg:w-1/4 relative" @click="generalPopup.click">{{
          generalPopup.buttonText
        }}</vs-button>
      </div>
    </vs-popup>

    <div id="content-area" :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]">
      <div id="content-overlay"></div>

      <div class="content-wrapper">
        <the-navbar
          :navbar-color="navbarColor"
          :class="[{ 'text-white': isNavbarDark && !isThemeDark }, { 'text-base': !isNavbarDark && isThemeDark }]"
        />

        <div class="router-view">
          <div class="router-content" :class="{ 'mt-0': navbarType == 'hidden' }">
            <transition :name="routerTransition">
              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{
                    'pr-4 border-0 md:border-r border-t-0 border-b-0 border-l-0 border-solid border-grey-light': $route.meta.breadcrumb,
                  }"
                >
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb v-if="$route.meta.breadcrumb" class="ml-4 md:block hidden" />

                <!-- DROPDOWN -->
                <vs-dropdown class="ml-auto md:block hidden cursor-pointer" vs-trigger-click>
                  <vs-button radius icon="icon-settings" icon-pack="feather"></vs-button>

                  <vs-dropdown-menu class="w-32">
                    <vs-dropdown-item>
                      <div class="flex items-center" @click="$router.push('/pages/profile')">
                        <feather-icon icon="UserIcon" class="inline-block mr-2" svg-classes="w-4 h-4" />
                        <span>Profile</span>
                      </div>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <div class="flex items-center" @click="$router.push('/apps/todo')">
                        <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svg-classes="w-4 h-4" />
                        <span>Tasks</span>
                      </div>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <div class="flex items-center" @click="$router.push('/apps/email')">
                        <feather-icon icon="MailIcon" class="inline-block mr-2" svg-classes="w-4 h-4" />
                        <span>Inbox</span>
                      </div>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </transition>
            <div class="content-area__content">
              <back-to-top v-if="!hideScrollToTop" bottom="5%" visibleoffset="500">
                <vs-button icon="arrow_upward" class="shadow-lg" />
              </back-to-top>
              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle"></router-view>
              </transition>
            </div>
          </div>
        </div>
      </div>

      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import VxSidebar from '@/layouts/components/vx-sidebar/VxSidebar.vue';
import TheNavbar from '../components/TheNavbar.vue';
import TheFooter from '../components/TheFooter.vue';
import themeConfig from '@/../themeConfig.js';
import sidebarItems from '@/layouts/components/vx-sidebar/sidebarItems.js';
import BackToTop from 'vue-backtotop';
import upgradeAccount from '@/views/components/extra-components/profile/upgrade-account.vue';
import PromotionalModal from '@/views/components/extra-components/promotional/promotional-modal.vue';

export default {
  components: {
    VxSidebar,
    upgradeAccount,
    TheNavbar,
    TheFooter,
    BackToTop,
    PromotionalModal,
  },
  data() {
    return {
      navbarType: themeConfig.navbarType || 'floating',
      navbarColor: themeConfig.navbarColor || '#fff',
      footerType: themeConfig.footerType || 'static',
      routerTransition: themeConfig.routerTransition || 'none',
      isNavbarDark: false,
      routeTitle: this.$route.meta.pageTitle,
      disableCustomizer: themeConfig.disableCustomizer,
      windowWidth: window.innerWidth, //width of windows
      hideScrollToTop: themeConfig.hideScrollToTop,
      disableThemeTour: themeConfig.disableThemeTour,
      employer: this.$store.getters['employerModule/getUser'],
    };
  },
  computed: {
    sideBarItemsWithTags() {
      const items = sidebarItems;
      return items.map((item) => {
        let unread = null;
        if (item.name === 'Applications') {
          unread = Object.values(this.$store.state.applicationsModule.data).filter((app) => !app.read).length;
        }
        if (item.name === 'Chat') {
          unread = Object.values(this.$store.state.chatModule.data).reduce((ac, chat) => ac + (chat.empUnread ?? 0), 0);
        }
        return unread ? { ...item, tag: unread, tagColor: 'rgb(173, 202, 99)' } : item;
      });
    },
    purchaseCredits() {
      return this.$store.state.purchaseCredits;
    },
    generalPopup() {
      return this.$store.state.generalPopup;
    },
    promotionModal() {
      return this.$store.state.promotionModal;
    },
    showSideBar() {
      return this.$store.state.sidebarOpen;
    },
    isAppPage() {
      if (this.$route.path.includes('/apps/')) return true;
      else return false;
    },
    isThemeDark() {
      return this.$store.state.theme == 'dark';
    },
    sidebarWidth() {
      return this.$store.state.sidebarWidth;
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    contentAreaClass() {
      if (this.sidebarWidth == 'default') return 'content-area-default';
      else if (this.sidebarWidth == 'reduced') return 'content-area-reduced';
      else if (this.sidebarWidth) return 'content-area-full';
    },
    navbarClasses() {
      return {
        'navbar-hidden': this.navbarType == 'hidden',
        'navbar-sticky': this.navbarType == 'sticky',
        'navbar-static': this.navbarType == 'static',
        'navbar-floating': this.navbarType == 'floating',
      };
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType == 'hidden',
        'footer-sticky': this.footerType == 'sticky',
        'footer-static': this.footerType == 'static',
      };
    },
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark(val) {
      if (this.navbarColor == '#fff' && val) {
        this.updateNavbarColor('#353535');
      } else {
        this.updateNavbarColor('#fff');
      }
    },
  },
  created() {
    this.setSidebarWidth();
    if (this.navbarColor == '#fff' && this.isThemeDark) {
      this.updateNavbarColor('#353535');
    } else {
      this.updateNavbarColor(this.navbarColor);
    }
  },
  mounted() {
    const el = document.getElementsByClassName('vs-sidebar--background')[0];
    el.addEventListener('click', this.closeSidebar);
    // if ((!this.employer.showPromotion && this.employer.showPromotion !== false) || this.employer.showPromotion) {
    //   this.$store.dispatch('togglePromotionModal', {
    //     active: true,
    //   });
    // }
  },
  unmounted() {
    const el = document.getElementsByClassName('vs-sidebar--background')[0];
    el[0].removeEventListener('click', this.closeSidebar);
  },
  methods: {
    closeSidebar() {
      this.$store.dispatch('toggleSidebar', false);
    },
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      if (val == '#fff') this.isNavbarDark = false;
      else this.isNavbarDark = true;
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', false);
        this.$store.dispatch('updateSidebarWidth', 'no-sidebar');
        this.disableThemeTour = true;
      } else if (this.windowWidth < 1200) {
        this.$store.dispatch('updateSidebarWidth', 'reduced');
      } else {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
  },
};
</script>
