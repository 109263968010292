<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper">
      <vs-navbar class="vx-navbar navbar-custom" :color="navbarColor" :class="classObj">
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer mr-1" icon="MenuIcon" @click.stop="showSidebar"></feather-icon>
        <div class="vx-col hidden lg:block lg:w-1/2">
          <img src="@/assets/images/pages/login.png" style="width: 150px" alt="login" class="mx-auto" />
        </div>
        <vs-spacer></vs-spacer>

        <!-- NOTIFICATIONS -->
        <vs-dropdown id="navbar-credits" :vs-trigger-click="true">
          <vs-button icon="expand_more" :icon-after="true" class="py-2 px-4 text-sm">Credits</vs-button>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="purchaseCredits('Freelance')">{{ returnFreelanceCredits }} x Freelance</vs-dropdown-item>
            <vs-dropdown-item @click="purchaseCredits('Permanent')">{{ returnPermanentCredits }} x Permanent</vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>

        <vs-dropdown id="navbar-ntfs" vs-custom-content vs-trigger-click class="cursor-pointer ml-4" @click="markAsRead">
          <feather-icon
            icon="BellIcon"
            class="cursor-pointer mt-1 sm:mr-6 mr-2"
            :badge="Object.keys(unreadNotifications).length"
          ></feather-icon>
          <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">
            <div class="notification-top p-5 flex justify-between bg-primary text-white">
              <div class="flex flex-col">
                <p class="text-lg text-white mb-2 font-semibold">
                  {{ Object.keys(notifications).length }}
                  {{ Object.keys(notifications).length === 1 ? 'Notification' : 'Notifications' }}</p
                >
                <p v-if="notifications.length > 0"
                  ><span class="cursor-pointer" @click="deleteAllNotifications">Clear all notifications</span></p
                >
              </div>
              <div class="flex justify-items-center">
                <router-link to="/pages/notifications"><p class="text-white" @click="dropDownClose()">See all</p></router-link>
              </div>
            </div>

            <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--notifications-dropdown p-0" :settings="settings">
              <ul class="bordered-items">
                <li
                  v-for="ntf in notifications"
                  :key="ntf.id"
                  class="flex justify-between px-4 py-4 notification cursor-pointer border-solid border-t-0 border-r-0 border-l-0 border-b-2 border-gray-200"
                >
                  <div class="flex items-start" @click="routeNotification(ntf)">
                    <div class="mx-2">
                      <small v-if="ntf.userData && ntf.userData.userName" class="text-gray-600"
                        >{{ ntf.userData.userName }} <span v-if="ntf.type === 'New Message'">has sent you a</span></small
                      >

                      <div class="flex items-center">
                        <span
                          v-if="
                            ntf.type !== 'Job Expired' &&
                            ntf.type !== 'Job Expiring' &&
                            ntf.type !== 'reactivated' &&
                            ntf.type !== 'deactivated'
                          "
                          class="font-medium block notification-title"
                          :class="[`text-${ntf.type === 'applied' || 'New Message' ? 'primary' : 'danger'}`]"
                          >{{ ntf.type.replace(/^\w/, (c) => c.toUpperCase()) }}
                          <span class="text-sm"
                            ><span v-if="ntf.type === 'accepted'">your invite </span>for {{ ntf.jobData.jobTitle }}</span
                          >
                        </span>

                        <span v-else-if="ntf.type === 'deactivated' || ntf.type === 'reactivated'"
                          ><span :class="[`text-${ntf.type === 'reactivated' ? 'primary' : 'danger'}`]" class="text-sm font-medium"
                            >Your job {{ ntf.jobName }} has been {{ ntf.type }}</span
                          >
                          <div v-if="ntf.type === 'deactivated'" class="text-black text-sm"
                            >You can submit it for review from the Jobs tab</div
                          >
                          <div v-else class="text-black text-sm">Guides can now apply as normal</div>
                        </span>

                        <span v-else
                          ><div v-if="ntf.type === 'Job Expired'"
                            ><span class="text-sm text-danger font-medium">Your job {{ ntf.jobData.jobTitle }} just expired</span>
                            <div class="text-black text-sm">It has been moved to the Archived Jobs tab</div></div
                          >
                          <div v-if="ntf.type === 'Job Expiring'"
                            ><span class="text-sm text-danger font-medium">Your job {{ ntf.jobData.jobTitle }} is about to expire</span>
                            <div class="text-black text-sm">Once expired, it will be moved to the Archived Jobs tab</div></div
                          >
                        </span>
                      </div>

                      <small class="mt-1 whitespace-no-wrap text-gray-600">{{ elapsedTime(ntf.createdAt.toDate()) }}</small>
                      <br />
                    </div>
                  </div>
                  <div>
                    <feather-icon icon="XIcon" color="danger" @click="deleteNotification(ntf)"></feather-icon>
                  </div>
                </li>
              </ul>
            </VuePerfectScrollbar>
          </vs-dropdown-menu>
        </vs-dropdown>

        <!-- USER META -->
        <div class="the-navbar__user-meta flex items-center">
          <div class="text-right leading-tight hidden sm:block">
            <p class="font-semibold">{{ user_displayName }}</p>
            <small>Available</small>
          </div>
          <vs-dropdown id="navbar-menu-dropdown" vs-custom-content vs-trigger-click class="cursor-pointer">
            <div class="con-img ml-3">
              <img
                key="onlineImg"
                :src="activeUserImg"
                alt="user-img"
                width="40"
                height="40"
                class="rounded-full shadow-md cursor-pointer block"
              />
            </div>
            <vs-dropdown-menu class="vx-navbar-dropdown">
              <ul style="min-width: 9rem">
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="toProfile(), closeMenu()">
                  <feather-icon icon="UserIcon" svg-classes="w-4 h-4"></feather-icon>
                  <span class="ml-2">Profile</span>
                </li>

                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="toSettings(), closeMenu()">
                  <feather-icon icon="SettingsIcon" svg-classes="w-4 h-4"></feather-icon>
                  <span class="ml-2">Settings</span>
                </li>
                <vs-divider class="m-1"></vs-divider>
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout()">
                  <feather-icon icon="LogOutIcon" svg-classes="w-4 h-4"></feather-icon>
                  <span class="ml-2">Logout</span>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>
<!--         eslint-disable no-param-reassign
  -->
<!--         TODO FIX THIS
  -->
<script>
import VxAutoSuggest from '@/components/vx-auto-suggest/VxAutoSuggest.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'TheNavbar',
  directives: {
    'click-outside': {
      bind: function (el, binding) {
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        document.addEventListener('click', handler);
      },

      unbind: function (el) {
        document.removeEventListener('click', el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VxAutoSuggest,
    VuePerfectScrollbar,
  },
  props: {
    navbarColor: {
      type: String,
      default: '#fff',
    },
  },
  data() {
    return {
      ntfs: this.$store.state.notificationModule.data,
      employer: this.$store.state.employerModule.data,
      unreadArr: [],
      modalOpenBanner: false,
      selectedCreditType: '',
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      searchQuery: '',
      showFullSearch: false,

      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false,
    };
  },
  computed: {
    notifications() {
      // const ntfs = this.$store.state.notificationModule.data;
      let notiArr = [];
      Object.keys(this.ntfs).map((key) => {
        notiArr.push(this.ntfs[key]);
      });
      notiArr.sort(function (a, b) {
        return b.createdAt.seconds - a.createdAt.seconds;
      });
      return notiArr;
    },
    unreadNotifications: {
      get() {
        let unread = [];
        Object.keys(this.ntfs).map((ntf) => {
          if (!this.ntfs[ntf].read || this.ntfs[ntf].read !== true) {
            unread.push(this.ntfs[ntf]);
            this.unreadArr = unread;
          }
        });
        return this.unreadArr;
      },
      set(newValue) {
        this.unreadArr = newValue;
        return this.unreadArr;
      },
    },
    // HELPER
    sidebarWidth() {
      return this.$store.state.sidebarWidth;
    },
    breakpoint() {
      return this.$store.state.breakpoint;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.sidebarWidth == 'default') return 'navbar-default';
      else if (this.sidebarWidth == 'reduced') return 'navbar-reduced';
      else if (this.sidebarWidth) return 'navbar-full';
      else return false;
    },

    // BOOKMARK & SEARCH
    data() {
      return this.$store.state.navbarSearchAndPinList;
    },
    starredPages() {
      return this.$store.state.starredPages;
    },
    starredPagesLimited: {
      get() {
        return this.starredPages.slice(0, 10);
      },
      set(list) {
        this.$store.dispatch('arrangeStarredPagesLimited', list);
      },
    },
    starredPagesMore: {
      get() {
        return this.starredPages.slice(10);
      },
      set(list) {
        this.$store.dispatch('arrangeStarredPagesMore', list);
      },
    },
    returnFreelanceCredits() {
      if (
        this.$store.getters['employerModule/getUser'].freelanceJobPosts === '' ||
        !this.$store.getters['employerModule/getUser'].freelanceJobPosts
      ) {
        return '0';
      } else {
        return this.$store.getters['employerModule/getUser'].freelanceJobPosts;
      }
    },
    returnPermanentCredits() {
      if (
        this.$store.getters['employerModule/getUser'].permanentJobPosts === '' ||
        !this.$store.getters['employerModule/getUser'].permanentJobPosts
      ) {
        return '0';
      } else {
        return this.$store.getters['employerModule/getUser'].permanentJobPosts;
      }
    },

    // PROFILE
    user_displayName() {
      const userName = this.$store.getters['employerModule/getUser'].name;
      if (!userName) {
        return 'User';
      } else {
        return this.$store.getters['employerModule/getUser'].name + ' ' + this.$store.getters['employerModule/getUser'].surname;
      }
    },
    activeUserImg() {
      const logoUrl = this.$store.getters['companiesModule/getComp'];
      if (typeof logoUrl === 'undefined') {
        return 'https://firebasestorage.googleapis.com/v0/b/guidenova.appspot.com/o/placeholder_logo.jpeg?alt=media&token=dda26a7c-f390-4d8d-b25f-c059b528f2e6';
      } else {
        return logoUrl.logo;
      }
    },
  },
  watch: {
    $route() {
      if (this.showBookmarkPagesDropdown) this.showBookmarkPagesDropdown = false;
    },
  },
  methods: {
    markAsRead() {
      if (this.unreadNotifications.length > 0) {
        for (const ntf in this.ntfs) {
          if (!this.ntfs[ntf].read || this.ntfs[ntf].read !== true) {
            this.ntfs[ntf].read = true;
            this.$store.dispatch('notificationModule/patch', this.ntfs[ntf]);
          }
        }
        this.unreadNotifications = [];
      }
    },
    purchaseCredits(type) {
      if (!this.$store.getters['companiesModule/getComp'] || !this.$store.getters['employerModule/billingDetailsCheck']) {
        this.$store.dispatch('toggleGeneralPopup', {
          active: true,
          title: 'Your profile is incomplete',
          text: 'Please fill in your Company Information and Billing Details before purchasing Job Credits.',
          buttonText: this.$route.name !== 'Profile' ? 'Go to Profile' : 'Okay',
          click: () => {
            this.toProfile();
            this.$store.dispatch('toggleGeneralPopup', { active: false });
          },
        });
      } else {
        this.$store.dispatch('togglePurchaseCredits', {
          active: true,
          creditType: type,
          title: 'Purchase Job Credit',
          text: 'All jobs posts are active for 30 days. Credits never expire.',
        });
      }
    },
    toProfile() {
      if (this.$route.name !== 'Profile') {
        this.$router.push('/pages/profile');
      }
    },
    toSettings() {
      if (this.$route.name !== 'Settings') {
        this.$router.push('/pages/settings');
      }
    },
    closeMenu() {
      var body = document.getElementsByTagName('BODY')[0];
      body.click();
    },
    test() {
      console.log(this.notifications);
    },
    routeNotification(ntf) {
      if (ntf.type === 'reactivated' || ntf.type === 'deactivated' || ntf.type === 'Job Expiring') {
        if (this.$route.name !== 'Jobs') {
          this.$router.push({
            name: 'Jobs',
          });
        }
      }
      if (ntf.type === 'applied' || ntf.type === 'accepted') {
        if (this.$route.name !== 'ApplicationIn') {
          this.$router.push({
            name: 'ApplicationIn',
            params: { userToView: ntf.userId },
          });
        }
      }
      if (ntf.type === 'New Message') {
        if (this.$route.name !== 'chat') {
          this.$router.push({
            name: 'chat',
          });
        }
      }
      if (ntf.type === 'Job Expired') {
        if (this.$route.name !== 'Archived Jobs') {
          this.$router.push({
            name: 'Archived Jobs',
          });
        }
      }
      document.getElementById('dropdown').click();
    },
    dropDownClose() {
      document.getElementById('dropdown').click();
    },
    showSidebar() {
      this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
    selected(item) {
      this.$router.push(item.url);
      this.showFullSearch = false;
    },
    actionClicked(item) {
      // e.stopPropogation();
      this.$store.dispatch('updateStarredPage', {
        index: item.index,
        val: !item.highlightAction,
      });
    },
    showNavbarSearch() {
      this.showFullSearch = true;
    },
    showSearchbar() {
      this.showFullSearch = true;
    },
    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? ' years ' : ' year ') + 'ago';
      } else if (days > 0) {
        return days + (days > 1 ? ' days ' : ' day ') + 'ago';
      } else if (hours > 0) {
        return hours + (hours > 1 ? ' hrs ' : ' hour ') + 'ago';
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? ' mins ' : ' min ') + 'ago';
      } else if (seconds > 0) {
        // return seconds + (seconds > 1 ? `${seconds} sec ago` : 'just now');
        return 'Just now';
      }

      return 'Just now';
    },
    deleteNotification(ntf) {
      this.$store.dispatch('notificationModule/delete', ntf.id);
    },
    deleteAllNotifications() {
      let ntfIds = [];
      this.notifications.forEach((ntf) => {
        ntfIds.push(ntf.id);
      });
      this.$store.dispatch('notificationModule/deleteBatch', ntfIds);
    },
    outside: function () {
      this.showBookmarkPagesDropdown = false;
    },
  },
};
</script>
